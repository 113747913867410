<template>
	<div class="container h-100 my-2">
		<div class="row h-100 justify-content-center align-items-center">
			<div class="col-12 col-md-8 col-lg-6 col-xl-5">
				<router-link :to="{ name: 'Home' }" class="d-block mb-3"><font-awesome-icon class="mr-1" :icon="['fal', 'arrow-left']"/> {{ $t('global.retour') }}</router-link>
				<div class="box">
				<h2>{{ $t("compte.compte_validation") }}</h2>
					<form @submit.prevent="validForm">
						<template v-if="no_phone_on_user == false">
							<p>{{ $t('compte.msg_code_validation') }} : <b>{{ indicatif }} {{ phone }}</b></p>
							<!-- Code validation -->
							<div class="form-group">
								<label for="code_validation">{{ $t('compte.code_validation') }}</label>

								<div class="input-group">
									<input autocomplete="off" class="form-control" type="text" :placeholder="$t('compte.code_validation')" v-model="code_validation" id="code_validation" v-mask="'000000'" :class="{ 'is-invalid': errors && errors.indexOf('Erreur code validation') > -1 }">
								</div>
							</div>

							<!-- Message d'erreur si besoin -->
							<ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

							<!-- Bouton de validation du formulaire -->
							<div class="form-group">
								<button type="submit" class="mt-4 btn btn-primary btn-block rounded-pill">
									<font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" pulse /> {{ $t('global.verifier') }}
								</button>
							</div>
						</template>
					</form>
					<hr v-if="code_non_recu && no_phone_on_user == false">
					<a href="" v-if="no_phone_on_user == false" @click.prevent="code_non_recu ? code_non_recu = false : code_non_recu = true">{{ $t('compte.code_non_recu') }}</a>
					

					<form @submit.prevent="resendForm" v-if="code_non_recu">
						<div class="form-group ">
							<label>{{ $t("compte.mon_telephone") }}</label>
							<div class="row no-gutters phone">
								<div class="col-auto pr-2">
									<phoneInput v-model="phone_tab"></phoneInput>
								</div>
								<div class="col pr-2">
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text">{{ phone_tab.phone_indicatif }}</div>
										</div>
										<input type="text" class="form-control" v-model="phone_tab.phone_numero" :class="{ 'is-invalid': errors && errors.indexOf('Erreur new phone') > -1 }">
									</div>
								</div>
							</div>
						</div>

						<div class="form-group">
							<button type="submit" class="mt-4 btn btn-primary btn-block rounded-pill" v-if="no_phone_on_user == false">
								<font-awesome-icon v-if="isLoadingResend" :icon="['fas', 'spinner']" pulse /> {{ $t('global.renvoyer') }}
							</button>
							<button type="submit" class="mt-4 btn btn-primary btn-block rounded-pill" v-else>
								<font-awesome-icon v-if="isLoadingResend" :icon="['fas', 'spinner']" pulse /> {{ $t('action.envoyer') }}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'


	import Config from "@/mixins/Config.js";
	import User from "@/mixins/User.js";
	import Tools from "@/mixins/Tools.js";
	import Navigation from "@/mixins/Navigation.js";
	import router from "@/router";

	import Countries from '@/assets/lang/countries/countries'
	import Indicatif from '@/assets/lang/countries/indicatif'
	import { EventBus } from 'EventBus';
	export default {
		name: "CompteValidation",
		mixins: [Config, User, Tools, Navigation],
		props: {
			redirect: String
        },
		data () {
			return {
				code_validation: null,
				message_erreur_code: "",
				phone: null,
				indicatif: "+33",
				errors: [],
				isLoading: false,
				isLoadingResend: false,
				code_non_recu: false,
				no_phone_on_user: false,
				phone_tab: {
				    phone_indicatif: '+33',
				    phone_numero: null,
				    phone_country: "FRA"
				},
				indicatifs: {},
				countries: {},
				countries_iso: {},
				user: null
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.code_non_recu = false
				this.no_phone_on_user = false

				this.indicatifs = Indicatif
				this.countries = Countries[Vue.i18n.locale()].label
				this.countries_iso = Countries[Vue.i18n.locale()].iso_3

				if(this.$route.query.numero) {
					this.phone = this.$route.query.numero
					this.indicatif = this.$route.query.indicatif
					this.phone_tab.phone_numero = this.$route.query.numero
					this.phone_tab.phone_indicatif = this.$route.query.indicatif
					this.phone_tab.phone_country = Object.keys(this.indicatifs).find(key => this.indicatifs[key] === this.$route.query.indicatif)
				}
				else
				{
					//Je n'ai pas de numéro de téléphone donc j'affiche le renvoi
					this.code_non_recu = true
					this.no_phone_on_user = true
				}

				// this.user = await this.getUserById(user_id)
				// if(this.user.phone != null)
				// {
				// 	this.phone = this.user.phone
				// 	this.indicatif = this.user.indicatif
				// 	this.phone_tab.phone_numero = this.user.phone
				// 	this.phone_tab.phone_indicatif = this.user.indicatif
				// 	this.phone_tab.phone_country = this.user.country
				// }
				// else
				// {
				// 	//Je n'ai pas de numéro de téléphone donc j'affiche le renvoi
				// 	this.code_non_recu = true
				// 	this.no_phone_on_user = true
				// }
			},
			async validForm() {
				if(this.isLoading == false)
				{
					this.errors = []
					this.message_erreur_code = ""

					this.isLoading = true

					if(this.code_validation == "")
					{
						this.errors.push("Erreur code validation")
					}

					if(this.indicatif == "")
					{
						this.errors.push("Erreur indicatif")
					}
					
					if(this.phone == "")
					{
						this.errors.push("Erreur phone")
					}

					if(this.errors.length == 0)
					{
						let res = await this.validateCode(this.phone, this.indicatif, this.code_validation)
						if(res != null)
						{
							this.isLoading = false
							this.successToast("toast.user_validation_ok")
							await this.setConfig("valid_user", 1)
							EventBus.$emit("verified_user", true)
							
							if(this.redirect) {
								window.location = "https://" + this.redirect + "/"
							}
							else {
								this.$router.push({ name: "Home" })
							}
						}
					}
					else
					{
						this.isLoading = false
						
						if(this.errors.indexOf("Erreur indicatif") > -1 || this.errors.indexOf("Erreur phone") > -1)
						{
							this.message_erreur_code = "compte.erreur_indicatif_phone"
						}
					}
				}
			},

			async resendForm()
			{
				if(this.isLoadingResend == false)
				{
					this.isLoadingResend = true
					this.errors = []

					if(this.phone_tab.phone_numero == "")
					{
						this.errors.push("Erreur new phone")
					}

					if(this.errors.length == 0)
					{
						let user_id = this.getConfig("user_id")
						let country_code = Object.keys(this.countries_iso)[Object.values(this.countries_iso).findIndex(c => c == this.phone_tab.phone_country)]

						let res = await this.validatePhoneNumber(this.phone_tab.phone_numero, Countries[Vue.i18n.locale()].iso[country_code], user_id)
						this.isLoadingResend = false
						
						if(res != null)
						{
							this.successToast("toast.user_code_send")
							this.code_non_recu = false
							this.init_component()
						}
					}
				}
			},

			change_indicatif(country)
			{
				this.phone_tab.phone_indicatif = this.indicatifs[country]
				this.phone_tab.phone_country = country
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput'),
		}
	};
</script>